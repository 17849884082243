import React, { useState, useEffect } from 'react';

import { PageManager } from '+/components/PageManager';
import params from '+/utils/queryParams';
import cleanupData from '+/utils/cleanupData';

// Gatsby adds Directus to all typenames, and we assume this throughout the codebase.
const addDirectusToTypenames = (obj: DirectusPageData): DirectusPageData => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  Object.keys(obj).forEach(key => {
    if (key === '__typename') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = `Directus_${obj[key]}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      obj[key] = addDirectusToTypenames(obj[key]);
    }
  });
  return obj;
};

// This url fetches graphql. We use an API to hide the endpoint / credentials and make sure no one can send graphql we don't want.
const previewUrl =
  'https://mnj70ukk0a.execute-api.us-east-1.amazonaws.com/default/SPB-page-graphql-fetcher';

/*
 * For preview pages. Built on the fly when a visitor loads the page.
 */
const ClientDirectusPage = ({ pageContext }: ClientDirectusPageComponent) => {
  // Most of the logic for queries are in the fragments provided by gatsby. We send this to lambda function which builds the right query.
  // https://us-east-1.console.aws.amazon.com/lambda/home?region=us-east-1#/functions/SPB-page-graphql-fetcher?tab=code
  const fragments = pageContext.pageQuery
    .replace(/Directus_/g, '')
    .match(/fragment(\n|\r|.)*?\n}/g);

  const [pageData, setPageData] = useState<PageComponent>(null);

  let { domainName } = params;
  const { slug } = params;
  if (!domainName && typeof window !== 'undefined') {
    domainName = window.location.host;
  }

  useEffect(() => {
    fetch(previewUrl, {
      method: 'POST',
      body: JSON.stringify({ fragments, domainName, slug }),
    })
      .then(responsePageData => responsePageData.json())
      .then((directusPageData: DirectusPageData) =>
        setPageData(cleanupData(addDirectusToTypenames(directusPageData)) as PageComponent),
      );
  }, []);

  if (typeof window === 'undefined') return <div />;
  if (!slug) return <div> Missing slug query param. </div>;
  if (pageData) return <PageManager pageData={pageData} />;
  return null;
};

export default ClientDirectusPage;
